export const getStripeLink = (userEmail, sub) => {
  return {
    hourly: `https://buy.stripe.com/7sIdSadH5bJ19vG288?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    "5_hours": `https://buy.stripe.com/8wM3dwdH5cN5dLWdQR?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    "10_hours": `https://buy.stripe.com/5kA3dw5az28r0ZafZ0?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    "20_hours": `https://buy.stripe.com/28o8xQ46v5kD7ny5kn?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    hourly60: `https://buy.stripe.com/14kaIC3jn25oaIM9AU?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    hd1day: `https://buy.stripe.com/4gw3ga2fj6lE2cgdQY?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    hd: `https://buy.stripe.com/bIY5oig699xQ6swcMW?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    // 'fhd': `https://buy.stripe.com/bIY3ga07b4dwbMQfZ4?prefilled_email=${userEmail}`, Old 10mbps plan
    fhd60: `https://buy.stripe.com/aEUaIC4nrbFY8AE14f?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    uhd: `https://buy.stripe.com/3cs5oi6vz6lEbMQcMT?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    your_own_game: `https://buy.stripe.com/8wM01k1YncN56jubIM?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    your_own_game_5_hours: `https://buy.stripe.com/eVa29sbyX14neQ05kp?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    vm_100GB_20hours: `https://buy.stripe.com/7sIcO646v00j37i6ou?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    vm_200GB_20hours: `https://buy.stripe.com/14k8xQbyXfZhgY83cj?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    vm_100GB_5hours: `https://buy.stripe.com/6oE3dw1Yn5kD8rCcMU?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    vm_100GB_1hour: `https://buy.stripe.com/9AQdSaeL9eVd6ju6oE?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    vm_512GB_20hours: `https://buy.stripe.com/6oE15ocD100jcHSeV3?prefilled_email=${userEmail}&client_reference_id=${sub}`,
    vm_1TB_20hours: `https://buy.stripe.com/7sI7tM6eD7sL6ju5ku?prefilled_email=${userEmail}&client_reference_id=${sub}`,
  };
};
