import React from 'react';
import './PricingCard.scss'
import classNames from "classnames";
import { Link } from 'react-router-dom';

const PricingCard = ({hideCTA=false, title, price, strikethroughPrice, text, subtext, secondSubtext, length, loading, buyButtonText, handleBuyClick, isPromo = false, isSignedIn= false, isHourly = false, isBeta=false}) => {
    return (
        <div className={
            classNames({
                'pricing-card': true,
                'promo-header': isPromo,
            })
        }>
            <div className='card-header'>
                {title}
                {isPromo && !isBeta && <div className='recommended-subheader'>New</div>}
                {isBeta && <div className='recommended-subheader'>Beta†</div>}
            </div>
            <div className='card-body'>
                {
                    isHourly ?
                        (
                            <div className='price-value'>
                                ₹ <s>{strikethroughPrice}</s> {price}
                            </div>
                        )
                        :
                        (
                            <div className='price-value'>
                                ₹ <s>{strikethroughPrice}</s> {price} <br/> <small>for <strong>{length.toUpperCase()}</strong></small>
                            </div>
                        )
                }
                <div>
                    {text}
                </div>
                <small className="card-subtext">
                    {subtext}
                </small>
                { secondSubtext ?  <small className="card-subtext-second">
                    {secondSubtext}
                </small> : null }
            </div>
            {
                isHourly ? 
                    (null)
                    :
                    (<strong>
                        Unlimited**
                    </strong>)
            }
            <button disabled={loading} className={
                classNames({
                    'pricing-buy-button': true,
                    'start-trial': !isSignedIn,
                    'buy-on-stripe': isSignedIn,
                    'hide-cta': hideCTA,
                })
            } onClick = {handleBuyClick}>
                {loading ? <span className="spinner"></span> : buyButtonText}
            </button>
            <div className='pay-by-upi'>
                <Link to="/contact-us?source=pricing">
                    Contact us to pay via UPI
                </Link>
            </div>
        </div>
    );
};

export default PricingCard;
