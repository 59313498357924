import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import { useState, useEffect } from "react";
import { Auth, Hub } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";

function ConfirmAccountPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [submittedCode, setSubmittedCode] = useState(false);
  const [resentCode, setResentCode] = useState(false);
  const { t } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    // Check if username is passed via state; if not, redirect back to the signup page
    if (
      location.state &&
      location.state.username &&
      location.state.password &&
      location.state.phone
    ) {
      setUsername(location.state.username);
      setPassword(location.state.password);
      setPhone(location.state.phone);
    } else {
      navigate("/signup"); // Adjust the route as necessary for your application
    }
  }, [location, navigate]);

  const handleConfirm = async (e) => {
    setSubmittedCode(true);
    e.preventDefault();
    try {
      await Auth.confirmSignUp(username, code);
      await Auth.signIn(username, password); // Add automatic sign-in after confirmation
      navigate("/"); // Redirect to the dashboard or preferred route after sign-in
      setSubmittedCode(false);
      posthog?.capture("sign_up_account_confirmed", {
        email: username,
      });
    } catch (error) {
      console.error("Error confirming account:", error);
      setError(error.message || "Failed to confirm account.");
      setSubmittedCode(false);
      posthog?.capture("sign_up_account_confirmation_error", {
        email: username,
        code: code,
        error: error.message,
      });
    }
  };

  const handleResendCode = async () => {
    setResentCode(true);
    try {
      await Auth.resendSignUp(username);
      setMessage("A new confirmation code has been sent to your email.");
      setResentCode(false);
      posthog?.capture("sign_up_account_confirmation_resend_code", {
        email: username,
      });
    } catch (error) {
      console.error("Error resending confirmation code:", error);
      setError(error.message || "Failed to resend confirmation code.");
      setResentCode(false);
      posthog?.capture("sign_up_account_confirmation_resend_code_error", {
        email: username,
        error: error.message,
      });
    }
  };

  return (
    <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
      <SignedOutHeader />
      <form onSubmit={handleConfirm}>
        <div className="max-w-md mx-auto my-10 p-6 rounded-lg shadow-md bg-base-300">
          <h1 className="text-lg font-bold mb-4 text-center">
            {t("confirm_account")}
          </h1>
          <div className="mb-4">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder={t("username")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
              disabled={true} // Disable the username field
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder={t("enter_code")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
            />
          </div>
          <button
            onClick={handleConfirm}
            className="btn btn-primary w-full text-primary-content py-2 px-4 rounded-md"
            disabled={submittedCode}
            type="submit"
          >
            {t("confirm_account")}
          </button>
          <button
            onClick={handleResendCode}
            className="btn btn-secondary mt-4 w-full text-secondary-content py-2 px-4 rounded-md"
            disabled={resentCode}
          >
            {t("resend_code")}
          </button>
          {message && <p className="mt-4 text-sm text-info">{message}</p>}
          {error && <p className="mt-4 text-sm text-error">{error}</p>}
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default ConfirmAccountPage;
