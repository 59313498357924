// HowItWorks.js

import React from "react";
import {
  CpuChipIcon,
  GlobeAsiaAustraliaIcon,
  PlayCircleIcon,
} from "@heroicons/react/24/solid";
import { withTranslation } from "react-i18next";

class HowItWorks extends React.Component {
  render() {
    const { t, howItWorksRef } = this.props;
    return (
      <section ref={howItWorksRef} className="pt-16">
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          {t("how_it_works")}
        </h2>
        <div className="flex flex-col md:flex-row text-center text-2xl">
          <div className="md:w-1/3 flex-col gap-10 py-[16px] px-[24px]">
            <div className="text-center inline-block">
              <GlobeAsiaAustraliaIcon className="h-28 w-28 text-primary" />
            </div>
            <div className="text-center">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("how_it_works_1"),
                }}
              />
            </div>
          </div>
          <div className="md:w-1/3 flex-col gap-10 py-[16px] px-[24px]">
            <div className="text-center inline-block">
              <CpuChipIcon className="h-28 w-28 text-primary" />
            </div>
            <div className="text-center">{t("how_it_works_2")}</div>
          </div>
          <div className="md:w-1/3 flex-col gap-10 py-[16px] px-[24px]">
            <div className="text-center inline-block">
              <PlayCircleIcon className="h-28 w-28 text-primary" />
            </div>
            <div className="text-center">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("how_it_works_3"),
                }}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(HowItWorks);
