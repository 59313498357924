import React from "react";
import "./MoonlightLinks.scss";
import { useTranslation } from "react-i18next";

function MoonLightLinks({ userEmail, checkoutLink, buttonText }) {
  const { t } = useTranslation();
  return (
    <div className="moonlight-download-links">
      <span
        dangerouslySetInnerHTML={{
          __html: t("download_moonlight"),
        }}
      />
      <a
        className="underline"
        target="_blank"
        href="https://github.com/moonlight-stream/moonlight-qt/releases/download/v6.1.0/MoonlightSetup-6.1.0.exe"
      >
        Windows
      </a>
      <a
        className="underline"
        target="_blank"
        href="https://github.com/moonlight-stream/moonlight-qt/releases/download/v6.1.0/Moonlight-6.1.0.dmg"
      >
        MacOS
      </a>
      <a
        className="underline"
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.limelight"
      >
        Android and Smart TVs
      </a>
      <a
        className="underline"
        target="_blank"
        href="https://apps.apple.com/us/app/moonlight-game-streaming/id1000551566"
      >
        iOS
      </a>
      <br />
      {t("other_platforms")}
      <a
        className="underline"
        target="_blank"
        href="https://moonlight-stream.org/#"
      >
        {t("here")}.
      </a>
    </div>
  );
}

export default MoonLightLinks;
