import React from 'react';
import './GameawayLogo.scss';
import {useNavigate} from "react-router-dom";

const GameawayLogo = () => {
    const navigate = useNavigate();
    return (
        <div className='gameaway-logo'>
            <h1 onClick={()=>{ navigate('/')} }>
                GameAway
            </h1>
        </div>
    );
};

export default GameawayLogo;
