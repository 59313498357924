import React from "react";

class StripeSkeleton extends React.Component {
  render() {
    return (
      <div class="bg-gray-100 p-4 min-h-screen flex flex-row items-top justify-center">
        <div className="w-1/2 pr-12 bg-gray-100 hidden md:block">
          <div className="h-full w-7/12 px-10 mt-9 ml-auto gap-10">
            <div className="skeleton w-full h-6 bg-gray-200"></div>
            <div className="skeleton w-64 h-16 mt-9 pr-12 bg-gray-200"></div>
          </div>
        </div>
        <div className="w-1/2 pl-10 bg-gray-50 hidden md:block">
          <div className="h-full w-7/12 px-10 mt-9 mr-auto gap-10">
            <div className="skeleton w-32 h-6 bg-slate-100"></div>
            <div className="skeleton w-full h-20 mt-5 pr-12 bg-slate-100"></div>
            <div className="skeleton w-full h-20 mt-8 pr-12 bg-slate-100"></div>
            <div className="skeleton w-full h-10 mt-8 pr-12 bg-slate-100"></div>
            <div className="skeleton w-full h-20 mt-8 pr-12 bg-slate-100"></div>
            <div className="skeleton w-full h-12 mt-12 pr-12 bg-blue-600"></div>
          </div>
        </div>
        <div className="w-full bg-gray-50 justify-center items-center mx-auto md:hidden">
          <div className="h-full w-full px-2 mt-2 mr-auto gap-10">
            <div className="skeleton w-full h-6 bg-gray-200"></div>
            <div className="skeleton w-64 h-16 mt-9 bg-gray-200 mx-auto"></div>
            <div className="skeleton w-full h-20 mt-16 pr-12 bg-slate-100"></div>
            <div className="skeleton w-full h-20 mt-8 pr-12 bg-slate-100"></div>
            <div className="skeleton w-full h-10 mt-8 pr-12 bg-slate-100"></div>
            <div className="skeleton w-full h-20 mt-8 pr-12 bg-slate-100"></div>
            <div className="skeleton w-full h-12 mt-12 pr-12 bg-blue-600"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default StripeSkeleton;
