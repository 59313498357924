import { useState, useEffect } from "react";
import React from "react";
import { Auth } from "aws-amplify";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";

function SignInPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const posthog = usePostHog();

  const handleSignIn = async (e) => {
    setSubmitted(true);
    e.preventDefault();
    try {
      const user = await Auth.signIn(username.trim(), password);
      console.log("Sign in successful!", user);
      navigate("/");
      setSubmitted(false);
      posthog?.capture("sign_in_successful", {
        email: username,
      });
    } catch (error) {
      console.error("Error signing in:", error);
      setError(
        error.message.replace("PreAuthentication failed with error ", "") ||
          "Failed to sign in."
      );
      setSubmitted(false);
      posthog?.capture("sign_in_failure", {
        email: username,
        error: error.message,
      });
    }
  };

  return (
    <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
      <SignedOutHeader />
      <form onSubmit={handleSignIn}>
        <div>
          <div className="max-w-md mx-auto my-10 p-6 rounded-lg shadow-md bg-base-300">
            <h1 className="text-lg font-bold mb-4 text-center">
              {t("sign_in")}
            </h1>
            <div className="mb-4">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder={t("username")}
                className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
              />
            </div>
            <div className="mb-4">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t("password")}
                className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
              />
            </div>
            <button
              onClick={handleSignIn}
              className="btn btn-primary w-full bg-primary text-primary-content py-2 px-4 rounded-md hover:bg-primary"
              disabled={submitted}
              type="submit"
            >
              {t("sign_in")}
            </button>
            {error && <p className="mt-4 text-sm text-error">{error}</p>}
            <div className="text-sm mt-4">
              <a href="/forgot-password" className="font-medium text-info link">
                {t("forgot_password")}
              </a>
            </div>
            <p className="mt-4 text-center text-sm text-base-content">
              {t("dont_have_account")}{" "}
              <a href="/signup" className="link text-info">
                {t("sign_up_here")}
              </a>
            </p>
          </div>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default SignInPage;
