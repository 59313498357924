export const getPriceId = () => {
  return {
    hourly: {
      price: `price_1No8wiSAcn2IgTIt41gqDPCc`,
      adjustable_quantity: true,
    },
    "5_hours": {
      price: `price_1No8wGSAcn2IgTItqnCXXnjB`,
      adjustable_quantity: false,
    },
    "10_hours": {
      price: `price_1No8vvSAcn2IgTItUMeGfxab`,
      adjustable_quantity: false,
    },
    "20_hours": {
      price: `price_1No8vJSAcn2IgTItguzieHSN`,
      adjustable_quantity: false,
    },
    vm_100GB_20hours: {
      price: `price_1O1TvISAcn2IgTItKY168MFP`,
      adjustable_quantity: false,
    },
    vm_200GB_20hours: {
      price: `price_1O1Tz3SAcn2IgTItlpasERSV`,
      adjustable_quantity: false,
    },
    vm_100GB_5hours: {
      price: `price_1O310wSAcn2IgTItUHQKWfP8`,
      adjustable_quantity: false,
    },
    vm_100GB_1hour: {
      price: `price_1OHVFbSAcn2IgTItrLOwkzm2`,
      adjustable_quantity: false,
    },
    vm_512GB_20hours: {
      price: `price_1O8hksSAcn2IgTIteysuKDAg`,
      adjustable_quantity: false,
    },
    vm_1TB_20hours: {
      price: `price_1O8htpSAcn2IgTItklR6DXgp`,
      adjustable_quantity: false,
    },
  };
};
