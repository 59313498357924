import React, { Component } from "react";
import "./PricingPage.scss";
import PricingCard from "../PricingCard/PricingCard";
import { useLocation, useNavigate } from "react-router-dom";
import CustomerSupport from "../CustomerSupport/CustomerSupport";
import AuthContext from "../AuthContext";
import { getStripeLink } from "../../utils/getStripeLink";
import GameawayHeader from "../GameawayHeader/GameawayHeader";
import { getPriceId } from "../../utils/getPriceId";
import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import Footer from "../Footer/Footer";

class PricingPage extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      loadingStripeLink: false,
    };
  }

  handleBuyClick = async (selectedPlan) => {
    const { user, userEmail, signIn, stripeCustomerId, sub } = this.context;

    if (!!userEmail) {
      const prices = getPriceId();
      this.setState({ loadingStripeLink: true });
      await fetch(
        "https://v1wapno6ch.execute-api.ap-south-1.amazonaws.com/prod/getStripeCheckout-prod-hello",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: user.signInUserSession.idToken.jwtToken,
          },
          body: JSON.stringify({
            priceId: prices[selectedPlan]["price"],
            adjustableQuantity: prices[selectedPlan]["adjustable_quantity"],
          }),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.checkout_url) {
            window.open(data.checkout_url, "_blank");
          } else {
            const buyLink = getStripeLink(userEmail, sub)[selectedPlan];
            window.open(buyLink);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          const buyLink = getStripeLink(userEmail, sub)[selectedPlan];
          window.open(buyLink);
        });
      this.setState({ loadingStripeLink: false });
    } else {
      signIn();
    }
  };

  render() {
    const { userEmail, signOut } = this.context;

    const isSignedIn = !!userEmail;
    const buyButtonText = isSignedIn ? "Buy Now on Stripe" : "Sign Up to Pay";

    return (
      <div className="pricing-page">
        <SignedOutHeader context={this.context} />
        <h3>
          Pick your plan for <i>ultra low latency</i> cloud gaming on
          high-powered PCs in the cloud at up to 4K/60 FPS
        </h3>
        <div className="one-month-notifier">
          (All plans end automatically, <b> no recurring charges</b>)
        </div>
        <div className="gta-notifier">
          <b>You must own the games you want to play on Steam/Epic</b>
        </div>
        <div className="price-plan-block">
          <div className="price-plan-container">
            <h3>
              Play games from our pre-defined list or add hours to your Virtual
              Gaming PC
            </h3>
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="Hourly"
              price="50 per hour"
              strikethroughPrice="55"
              text="Flexible option to pay for only as much as you need."
              subtext="* Includes all games, can be used to top up Virtual Gaming PC plan. *"
              length="1 month"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("hourly")}
              buyButtonText={buyButtonText}
              isHourly={true}
            />
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="5 Hours"
              price="225"
              strikethroughPrice="250"
              text="Discounted package for 5 hours."
              subtext="* Includes all games, can be used to top up Virtual Gaming PC plan. *"
              length="1 month"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("5_hours")}
              buyButtonText={buyButtonText}
              isHourly={true}
            />
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="10 Hours"
              price="400"
              strikethroughPrice="500"
              text="Discounted package for 10 hours."
              subtext="* Includes all games, can be used to top up Virtual Gaming PC plan. *"
              length="1 month"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("10_hours")}
              buyButtonText={buyButtonText}
              isHourly={true}
            />
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="20 Hours"
              price="700"
              strikethroughPrice="900"
              text="Discounted package for 20 hours."
              subtext="* Includes all games, can be used to top up Virtual Gaming PC plan. *"
              length="1 month"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("20_hours")}
              buyButtonText={buyButtonText}
              isHourly={true}
            />
          </div>
          <div className="price-plan-container">
            <h3>Virtual Gaming PC: Play any game you own</h3>
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="Virtual Gaming PC - 100 GB"
              price="399"
              strikethroughPrice="499"
              text="One Week Storage: Install your games and software on a Virtual Gaming PC with 100 GB storage. Includes 5 hours of play. Add more hours anytime."
              subtext="* Data stored for 1 week, includes all our supported games *"
              secondSubtext="Activated within 12 hours"
              length="5 hours + 1 hour for installation"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("vm_100GB_5hours")}
              buyButtonText={buyButtonText}
              isBeta={true}
              isPromo={true}
              isHourly={true}
            />
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="Virtual Gaming PC - 100 GB"
              price="549"
              strikethroughPrice="699"
              text="One Month Storage: Install your games and software on a Virtual Gaming PC with 100 GB storage. Includes 1 hour of play. Add more hours anytime."
              subtext="* Data stored for 1 month, includes all our supported games *"
              secondSubtext="Activated immediately"
              length="1 hour"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("vm_100GB_1hour")}
              buyButtonText={buyButtonText}
              isBeta={true}
              isPromo={true}
              isHourly={true}
            />
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="Virtual Gaming PC - 100 GB"
              price="1199"
              strikethroughPrice="1399"
              text="One Month Storage: Install your games and software on a Virtual Gaming PC with 100 GB storage. Includes 20 hours of play. Add more hours anytime."
              subtext="* Data stored for 1 month, includes all our pre-installed games *"
              secondSubtext="Activated immediately"
              length="20 hours + 1 hour for installation"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("vm_100GB_20hours")}
              buyButtonText={buyButtonText}
              isBeta={true}
              isPromo={true}
              isHourly={true}
            />
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="Virtual Gaming PC - 200 GB"
              price="1699"
              strikethroughPrice="1999"
              text="One Month Storage: Install your games and software on a Virtual Gaming PC with 200 GB storage. Includes 20 hours of play. Add more hours anytime."
              subtext="* Data stored for 1 month, includes all our pre-installed games *"
              secondSubtext="Activated immediately"
              length="20 hours + 1 hours for installation"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("vm_200GB_20hours")}
              buyButtonText={buyButtonText}
              isBeta={true}
              isPromo={true}
              isHourly={true}
            />
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="Virtual Gaming PC - 512 GB"
              price="3499"
              strikethroughPrice="3999"
              text="One Month Storage: Install your games and software on a Virtual Gaming PC with 512 GB storage. Includes 20 hours of play. Add more hours anytime."
              subtext="* Data stored for 1 month, includes all our pre-installed games *"
              secondSubtext="Your plan will be activated within 12 hours"
              length="20 hours + 1 hours for installation"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("vm_512GB_20hours")}
              buyButtonText={buyButtonText}
              isBeta={true}
              isPromo={true}
              isHourly={true}
            />
            <PricingCard
              isSignedIn={isSignedIn}
              hideCTA={false}
              title="Virtual Gaming PC - 1 TB"
              price="4999"
              strikethroughPrice="5499"
              text="One Month Storage: Install your games and software on a Virtual Gaming PC with 1 TB storage. Includes 20 hours of play. Add more hours anytime."
              subtext="* Data stored for 1 month, includes all our pre-installed games *"
              secondSubtext="Your plan will be activated within 12 hours"
              length="20 hours + 1 hours for installation"
              loading={this.state.loadingStripeLink}
              handleBuyClick={() => this.handleBuyClick("vm_1TB_20hours")}
              buyButtonText={buyButtonText}
              isBeta={true}
              isPromo={true}
              isHourly={true}
            />
          </div>
        </div>
        <div className="disclaimer">
          {/***All Monthly Unlimited plans are subject to a Fair Usage Policy of up to 40 hours. After 40 hours your bitrate will be reduced to 0.5 Mbps and your game will disconnect every 30 minutes. Make sure the games are shut down when*/}
          {/*not in use.*/}
          {/*<br/>*/}
          †Install all data to D:\ drive for it to be stored. Known issues: 1.
          You cannot switch between the 100GB, 200GB, 512GB and 1TB plans
          without reinstalling games.
        </div>
        <Footer />
        {/*<div className='pricing-disclaimer'>*/}
        {/*    *Limited time only.*/}
        {/*</div>*/}
      </div>
    );
  }
}

export default PricingPage;
