import React from "react";
import TestimonialCarousel from "../TestimonialCarousel/TestimonialCarousel";
import { withTranslation } from "react-i18next";

class Testimonials extends React.Component {
  render() {
    const { t } = this.props;
    const testimonials = [
      {
        name: "Chaitanya",
        text: "My gaming experience with GameAway was fantastic. The game worked flawlessly, providing a smooth and enjoyable experience. It's evident that your team has put in great effort to make gaming more accessible in India.",
      },
      {
        name: "Kunal",
        text: "I will buy your virtual PC plans and then download and play games to it. Just what I wanted. Will recommed to others also.",
      },
      { name: "obviyus", text: "I can't tell it's not native." },
      {
        name: "Ramesh",
        text: "I really loved GameAway. It worked as expected and honestly, it exceeded my expectations. It was very easy to set up and there was almost no lag. I loved it. Thank you!",
      },
      {
        name: "Andro",
        text: "I Have To Say You've Done Fantastic Job All Games Were Running Fine You Really Brought Future Of Cloud Gaming To India👍",
      },
      {
        name: "Abhijith",
        text: "The gameplay was as expected didn't really find any faults, overall it was a smooth experience and keep up the good work 👍",
      },
      {
        name: "Ashish",
        text: "The game experience was fantastic. I enjoyed the whole time i played. This gaming service is absolutely perfect. Thank you.",
      },
      {
        name: "Kadir, Turkey",
        text: "The game system is really nice. I like.",
      },
      {
        name: "Satyam",
        text: "Just amazing work dude you and your team did a great job. The service is amazing i enjoyed it and the gameplay is so smooth didn't experienced any lag. This is the first time i experienced a lag free gameplay on cloudgaming. Surely buying a monthly plan in future. Thank you",
      },
      {
        name: "Vivek",
        text: "It was nice experience with gameway. I am amused with quality which I got, it seems like I am playing on my own laptop. Great work guys please keep up the good work.",
      },
      {
        name: "Mukund",
        text: "Hi, So first thing, your service is simply the best in the India. Damn guys its just smooooth, I’ve tried every india based services and they all kinda sucks but your service is smooth. Great work guys",
      },
      {
        name: "Mukka",
        text: "Your service is very smooth. I purchase next time 1 month plan",
      },
    ];

    const { testimonialRef } = this.props;
    return (
      <section ref={testimonialRef} className="pt-16">
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          {t("what_our_users_think")}
        </h2>
        <div>
          <TestimonialCarousel testimonials={testimonials} />
        </div>
      </section>
    );
  }
}

export default withTranslation()(Testimonials);
