import React from "react";
import AuthContext from "../AuthContext";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function ReferredBanner(props) {
  const { show, signedOut } = props;
  const { t } = useTranslation();
  if (!show) {
    return;
  }
  return (
    <div className="card bg-accent text-accent-content w-fit lg:w-11/12 flex flex-col lg:flex-row mb-8 mx-auto gap-6">
      <div className="card-body flex flex-col lg:flex-row">
        <div className="flex flex-col w-full lg:w-2/3 justify-items-center items-center text-center mx-auto">
          {signedOut ? (
            <h2 className="card-title">{t("referred_signed_out")}</h2>
          ) : (
            <h2 className="card-title">{t("referred_signed_in")}</h2>
          )}
        </div>
      </div>
    </div>
  );
}
