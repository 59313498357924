import { useState } from "react";
import { Auth } from "aws-amplify";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import countries from "../../countries.json";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";

function SignUpPage() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("+91"); // Default country code
  const navigate = useNavigate();
  const { t } = useTranslation();
  const posthog = usePostHog();

  const handleSignUp = async (e) => {
    setSubmitted(true);
    e.preventDefault();
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address.");
      setSubmitted(false);
      posthog?.capture("sign_up_invalid_email", {
        email: email,
      });
      return;
    }
    if (!isValidPassword(password)) {
      setError(
        "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter and one number."
      );
      setSubmitted(false);
      posthog?.capture("sign_up_invalid_password", {
        password: password,
      });
      return;
    }
    const fullPhoneNumber = `${selectedCountryCode}${phone}`;
    if (!isValidPhoneNumber(fullPhoneNumber)) {
      setError("Phone number must contain only digits (e.g., 999999999).");
      setSubmitted(false);
      posthog?.capture("sign_up_invalid_phone_number", {
        phone: fullPhoneNumber,
      });
      return;
    }
    try {
      const signUpResponse = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional
          phone_number: fullPhoneNumber ? `${fullPhoneNumber}` : undefined, // Phone number must be in E.164 format
        },
      });
      console.log("Sign up success:", signUpResponse);
      posthog?.capture("sign_up_success", {
        email: email,
      });
      navigate("/confirm-account", {
        state: { username: email, password: password, phone: phone },
      }); // Redirect to confirm account page with username
      setSubmitted(false);
    } catch (error) {
      setSubmitted(false);
      console.error("Error during sign up:", error);
      posthog?.capture("sign_up_failed", {
        email: email,
        error: error.message,
      });
      setError(
        error.message.replace("PreSignUp failed with error ", "") ||
          "Error during sign up."
      );
    }
  };

  function isValidEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  function isValidPassword(password) {
    // Example: Validate password to be minimum 8 characters which contain at least one letter and one number
    const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return re.test(password);
  }

  function isValidPhoneNumber(phone) {
    // E.164 format validation
    const re = /^\+[1-9]\d{1,14}$/;
    return re.test(phone);
  }

  return (
    <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
      <SignedOutHeader />
      <form onSubmit={handleSignUp}>
        <div className="max-w-md mx-auto my-10 p-6 rounded-lg shadow-md bg-base-300">
          <h1 className="text-lg font-bold mb-4 text-center">{t("signup")}</h1>
          <div className="mb-4">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("email")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("password")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
            />
          </div>
          <div className="mb-4 flex flex-row gap-2">
            <select
              value={selectedCountryCode}
              onChange={(e) => setSelectedCountryCode(e.target.value)}
              className="mt-1 block px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered w-1/6 min-w-16"
            >
              {countries.map((country) => (
                <option key={country.name} value={`+${country.code}`}>
                  {country.cca2}
                  {"   "}
                  {country.flag}
                </option>
              ))}
            </select>

            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder={t("phone")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
            />
          </div>
          <button
            type="submit"
            onClick={handleSignUp}
            className="btn btn-primary w-full bg-primary text-primary-content py-2 px-4 rounded-md hover:bg-primary"
            disabled={submitted}
          >
            {t("signup")}
          </button>
          {error && <p className="mt-4 text-sm text-error">{error}</p>}
          <p className="mt-4 text-center text-sm text-base-content">
            {t("already_have_account")}{" "}
            <a href="/signin" className="text-info link">
              {t("sign_in_here")}
            </a>
          </p>
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default SignUpPage;
