import React, {Component} from 'react';
import './PayOrStartTrial.scss';
import CustomButton from "../CustomButton/CustomButton";
import {MdOpenInNew} from "react-icons/md";
import BuyButton from "../BuyButton/BuyButton";
import MoonLightLinks from "../MoonlightLinks/MoonlightLinks";

class PayOrStartTrial extends Component {
    render() {
        const {userEmail, setTrialDecisionMade} = this.props;
        return (
            <div className="pay-or-start-trial">
                <section className="intro-text">
                    <p>Play your favourite games on any device using <strong>Game Away</strong> and Moonlight<strong>*</strong>.</p>
                    <p>Get first 2 hours of gaming for free!</p>
                <CustomButton handleClick = {
                    () => setTrialDecisionMade()
                }>
                    Play/Start Free Trial
                </CustomButton>
                </section>
                <section className="moonlight-text">
                    <p><strong>*</strong>If you don't have it yet on your device, get Moonlight first:</p>
                    <MoonLightLinks />
                </section>
            </div>
        );
    }
}

export default PayOrStartTrial;