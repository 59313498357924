import React from "react";
import AuthContext from "../AuthContext";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import clsx from "classnames";
import { usePostHog } from "posthog-js/react";
import { useTranslation } from "react-i18next";

export default function SignedOutHeader(props) {
  const { userEmail, signIn, signOut } = React.useContext(AuthContext);
  const navigate = useNavigate(); // Initialize navigate function
  const posthog = usePostHog();
  const { i18n, t } = useTranslation();

  const handleEventAndNavigate = (path, eventName) => {
    posthog?.capture(eventName, { path, userEmail });
  };

  return (
    <div className="navbar bg-base-100 pt-2 pb-8">
      <div className="navbar-start">
        <div className="dropdown flex flex-row">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-300 rounded-box w-52"
          >
            <li>
              <a
                href="/"
                onClick={() => handleEventAndNavigate("/", "home_from_header")}
              >
                {t("home")}
              </a>
            </li>
            <li>
              <a
                href="/gamelist"
                onClick={() =>
                  handleEventAndNavigate("/gamelist", "gamelist_from_header")
                }
              >
                {t("game_list")}
              </a>
            </li>
            <li>
              <a
                href="/pricing"
                onClick={() =>
                  handleEventAndNavigate("/pricing", "pricing_from_header")
                }
              >
                {t("pricing")}
              </a>
            </li>
            <li>
              <a
                href="/faq"
                onClick={() =>
                  handleEventAndNavigate("/faq", "faq_from_header")
                }
              >
                {t("faqs")}
              </a>
            </li>
            <li className={clsx("block", { hidden: !userEmail })}>
              <a
                href="/user"
                onClick={() =>
                  handleEventAndNavigate("/user", "profile_from_header")
                }
              >
                {t("profile")}
              </a>
            </li>
          </ul>
          <select
            value={i18n.language}
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
              localStorage.setItem("language", e.target.value);
              posthog?.capture("language_changed", {
                language: e.target.value,
              });
            }}
            className="block px-3 py-2 rounded-md shadow-sm input input-bordered w-11 lg:hidden"
          >
            {[
              { code: "en", name: "🌐 English" },
              { code: "hi", name: "🇮🇳 हिन्दी" },
              { code: "hing", name: "🇮🇳 Hinglish" },
            ].map((lang) => (
              <option key={lang.code} value={lang.code}>
                {lang.name}
              </option>
            ))}
          </select>
        </div>
        <a className="btn btn-ghost text-xl hidden lg:inline-flex" href="/">
          <img src="./favicon-32x32.png" />
          <span>GameAway</span>
        </a>
      </div>
      <a className="btn btn-ghost text-xl lg:hidden navbar-center" href="/">
        <span>GameAway</span>
      </a>
      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal px-1">
          <li>
            <a
              href="/gamelist"
              onClick={() =>
                handleEventAndNavigate("/gamelist", "gamelist_from_header")
              }
            >
              {t("game_list")}
            </a>
          </li>
          <li>
            <a
              href="/pricing"
              onClick={() =>
                handleEventAndNavigate("/pricing", "pricing_from_header")
              }
            >
              {t("pricing")}
            </a>
          </li>
          <li>
            <a
              href="/faq"
              onClick={() => handleEventAndNavigate("/faq", "faq_from_header")}
            >
              {t("faqs")}
            </a>
          </li>
        </ul>
      </div>
      <div className="navbar-end gap-2">
        <select
          value={i18n.language}
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
            localStorage.setItem("language", e.target.value);
            posthog?.capture("language_changed", {
              language: e.target.value,
            });
          }}
          className="block px-3 py-2 rounded-md shadow-sm hidden lg:inline-flex input input-bordered w-fit"
        >
          {[
            { code: "en", name: "🌐 English" },
            { code: "hi", name: "🇮🇳 हिन्दी" },
            { code: "hing", name: "🇮🇳 Hinglish" },
          ].map((lang) => (
            <option key={lang.code} value={lang.code}>
              {lang.name}
            </option>
          ))}
        </select>
        {userEmail ? (
          <a className="btn btn-primary" onClick={signOut}>
            {t("sign_out")}
          </a>
        ) : (
          <a className="btn btn-primary" href="/signin">
            {t("sign_in")}
          </a>
        )}
        <a
          className={clsx(
            "btn btn-ghost btn-circle avatar",
            userEmail ? "hidden lg:inline-flex" : "hidden"
          )}
          href="/user"
          onClick={() => handleEventAndNavigate("/user", "profile_from_header")}
        >
          <UserCircleIcon tabIndex={0} role="button"></UserCircleIcon>
        </a>
      </div>
    </div>
  );
}
