import React, { Component } from "react";
import AuthContext from "../../components/AuthContext";
import GameawayHeader from "../../components/GameawayHeader/GameawayHeader";
import GameList from "../../components/GameList/GameList";

class GameListPage extends React.Component {
  static contextType = AuthContext;

  render() {
    const { userEmail, signOut } = this.context;

    const isSignedIn = !!userEmail;

    return (
      <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
        <div className="game-list-page">
          <GameList />
        </div>
      </div>
    );
  }
}

export default GameListPage;
