import React, { Component } from "react";
import "./SuggestGame.scss";

class SuggestGame extends Component {
  constructor(props) {
    super(props);
    this.state = {
      suggestedGame: "",
      isGameSuggested: false,
      showGameSuggestion: false,
    };
  }

  handleSuggestGameInput = (event) => {
    const { value } = event.target;
    this.setState({ suggestedGame: value });
  };

  sendSuggestion = async () => {
    this.setState({
      isGameSuggested: true,
    });
    if (this.props.user) {
      await fetch(
        "https://js57w4ep8i.execute-api.ap-south-1.amazonaws.com/default/requestNewGame",
        {
          method: "POST",
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: this.props.user.signInUserSession.idToken.jwtToken,
          },
          body: JSON.stringify({ game: this.state.suggestedGame }),
        }
      );
    } else {
      await fetch(
        "https://tbfdcyrurk.execute-api.ap-south-1.amazonaws.com/default/requestNewGame",
        {
          method: "POST",
          mode: "cors", // no-cors, *cors, same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify({ game: this.state.suggestedGame }),
        }
      );
    }
  };

  render() {
    return (
      <div className="suggest-game-block">
        <label htmlFor="suggest-game">
          <span>
            Don't see a game you want on the list? Let us know and we'll add it!
          </span>
          <div>
            <input
              className="input input-bordered input-sm"
              type="text"
              name="suggest-game"
              placeholder="Spiderman on Epic Store"
              value={this.state.suggestedGame}
              onChange={this.handleSuggestGameInput}
            />
            <button
              onClick={this.sendSuggestion}
              disabled={this.state.isGameSuggested}
              className="btn btn-sm btn-primary ml-4"
            >
              Submit
            </button>
            {this.state.isGameSuggested && (
              <span className="suggestion-confirmation">
                Thanks for the suggestion! We'll add this asap
              </span>
            )}
          </div>
          <span className="you-own-game-new">New:</span> You can install any
          Steam game you want using our{" "}
          <a href="/pricing" className="underline">
            Virtual Gaming PC
          </a>{" "}
          plan.
        </label>
      </div>
    );
  }
}

export default SuggestGame;
