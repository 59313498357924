import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Footer from "../Footer/Footer";
import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";

function ResetPasswordPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [submittedCode, setSubmittedCode] = useState(false);
  const [resentCode, setResentCode] = useState(false);
  const { t } = useTranslation();
  const posthog = usePostHog();

  useEffect(() => {
    // Check if username is passed via state; if so, autofill it
    if (location.state && location.state.username) {
      setUsername(location.state.username);
    }
  }, [location]);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setSubmittedCode(true);
    try {
      await Auth.forgotPasswordSubmit(username.trim(), code, newPassword);
      await Auth.signIn(username.trim(), newPassword); // Add automatic sign-in after confirmation
      navigate("/");
      setMessage("Password has been successfully reset.");
      setSubmittedCode(false);
      posthog?.capture("reset_password_success", {
        email: username,
      });
    } catch (error) {
      console.error("Error resetting password:", error);
      setError(error.message || "Failed to reset password.");
      setSubmittedCode(false);
      posthog?.capture("reset_password_error", {
        email: username,
        error: error.message,
      });
    }
  };

  const handleResendCode = async () => {
    setResentCode(true);
    try {
      await Auth.forgotPassword(username);
      setMessage("A new verification code has been sent to your email.");
      setResentCode(false);
      posthog?.capture("reset_password_email_resent", {
        email: username,
      });
    } catch (error) {
      console.error("Error resending verification code:", error);
      setError(error.message || "Failed to resend verification code.");
      setResentCode(false);
      posthog?.capture("reset_password_email_resent_error", {
        email: username,
        error: error.message,
      });
    }
  };

  return (
    <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
      <SignedOutHeader />
      <form onSubmit={handlePasswordReset}>
        <div className="max-w-md mx-auto my-10 p-6 rounded-lg shadow-md bg-base-300">
          <h1 className="text-lg font-bold mb-4 text-center">
            {t("reset_password")}
          </h1>
          <div className="mb-4">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder={t("username")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
            />
          </div>
          <div className="mb-4">
            <input
              type="text"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              placeholder={t("enter_code")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder={t("new_password")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
            />
          </div>
          <button
            onClick={handlePasswordReset}
            className="btn btn-primary w-full bg-primary text-primary-content py-2 px-4 rounded-md hover:bg-primary mt-4"
            disabled={submittedCode}
            type="submit"
          >
            {t("reset_password")}
          </button>
          <button
            onClick={handleResendCode}
            className="btn btn-secondary w-full text-secondary-content py-2 px-4 rounded-md hover:bg-secondary mt-4"
            disabled={resentCode}
          >
            {t("resend_code")}
          </button>
          {message && <p className="mt-4 text-sm text-green-500">{message}</p>}
          {error && <p className="mt-4 text-sm text-red-500">{error}</p>}
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default ResetPasswordPage;
