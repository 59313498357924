import React from "react";
import GameawayHeader from "../../components/GameawayHeader/GameawayHeader";
import AuthContext from "../../components/AuthContext";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";

class ContactUsPage extends React.Component {
  static contextType = AuthContext;

  render() {
    const { userEmail, signOut } = this.context;

    return (
      <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
        <SignedOutHeader context={this.context} />
        <h2 className="text-4xl font-extrabold no-underline pb-10">
          Contact Us
        </h2>
        <p>
          We at GameAway are always here to help you. Your feedback and
          inquiries are important to us. Feel free to get in touch with us
          through any of the following means:
        </p>

        <h2>Email</h2>
        <p>
          For any kind of support or queries, drop us an email at
          <a
            href="mailto:customer-support@dreambuilders.dev"
            className="underline"
          >
            {" "}
            customer-support@dreambuilders.dev
          </a>
          .
        </p>

        <h2>Discord</h2>
        <p>
          Join our Discord community for live discussions and updates. Follow
          this link to join:
          <a
            href="https://discord.gg/enfJGFcBrA"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {" "}
            GameAway Discord
          </a>
          .
        </p>

        <h2>Telegram</h2>
        <p>
          You can also connect with us on Telegram. Here is the link to our
          group:
          <a
            href="https://t.me/+O2N-1qn2_vxhNDY9"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {" "}
            GameAway Telegram
          </a>
          .
        </p>

        <h2>WhatsApp</h2>
        <p>
          Finally, you can also reach us via WhatsApp. Here is the link to our
          group:
          <a
            href="https://chat.whatsapp.com/DiRKnzQk0OE6woulWCaN3p"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            {" "}
            GameAway WhatsApp
          </a>
          .
        </p>

        <h2>Address</h2>
        <p className="address-line">GameAway Cloud Technologies LLP</p>
        <p className="address-line">
          Unit 3, 2nd Floor, Mahalaxmi Industrial Estate
        </p>
        <p className="address-line">Delisle Road, Worli</p>
        <p className="address-line">Mumbai 400013</p>
        <p className="address-line">Maharashtra</p>
        <p className="address-line">India</p>
        <p className="address-line">+91 81693 45534</p>
        <Footer />
      </div>
    );
  }
}

export default ContactUsPage;
