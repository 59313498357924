import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./TestimonialCarousel.scss"; // Import the CSS file

function TestimonialCarousel({ testimonials }) {
  return (
    <div>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        autoPlay={false}
        interval={6000}
        showStatus={false}
        showIndicators={false}
      >
        {testimonials.map((testimonial, index) => (
          <div
            key={index}
            className="card w-full h-full min-h-32 bg-neutral text-neutral-content"
          >
            <div className="flex flex-col h-full justify-center items-center max-w-2xl mx-auto p-4">
              <div className="text-2xl lg:text-3xl leading-relaxed px-4">
                {testimonial.text}
              </div>
              <p className="mt-7 font-bold text-2xl">{testimonial.name}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default TestimonialCarousel;
