import React from "react";
import "./LandingPageHeader.scss";
import GameawayLogo from "../GameawayLogo/GameawayLogo";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import classNames from "classnames";
import { MdClose } from "react-icons/md";

class LandingPageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHamburgerMenuOpen: false,
    };
  }
  render() {
    const {
      signIn,
      scrollToHowItWorks,
      scrollToGameList,
      scrollToTestimonials,
    } = this.props;
    return (
      <div className="landingpage-header">
        <div className="logo-container">
          <GameawayLogo />
        </div>
        <nav
          className={classNames({
            "nav-container": true,
            "is-hamburger-menu-open": this.state.isHamburgerMenuOpen,
          })}
        >
          <div className="sign-in secondary-mobile" onClick={signIn}>
            Sign in
          </div>
          <GiHamburgerMenu
            size={28}
            onClick={this.openHamburgerMenu}
            className={classNames({
              "hamburger-icon": true,
              hide: this.state.isHamburgerMenuOpen,
            })}
          />
          <MdClose
            size={28}
            onClick={this.closeHamburgerMenu}
            className={classNames({
              "close-icon": true,
              hide: !this.state.isHamburgerMenuOpen,
            })}
          />
          <ul
            className={classNames({
              hide: !this.state.isHamburgerMenuOpen,
            })}
          >
            <li onClick={scrollToHowItWorks}>How it works</li>
            <li onClick={scrollToTestimonials}>Testimonials</li>
            <li onClick={scrollToGameList}>Game List</li>
            <Link className="pricing" to="/pricing">
              <li>Pricing</li>
            </Link>
            <Link className="sign-in" to="/signin">
              <li>Sign In</li>
            </Link>
            <li className="sign-in" onClick={signIn}>
              Sign In
            </li>
          </ul>
        </nav>
      </div>
    );
  }

  openHamburgerMenu = () => {
    this.setState({ isHamburgerMenuOpen: true });
  };

  closeHamburgerMenu = () => {
    this.setState({ isHamburgerMenuOpen: false });
  };
}

export default LandingPageHeader;
