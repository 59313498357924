import React from 'react';
import CustomButton from "../CustomButton/CustomButton";

function BuyButton({userEmail, checkoutLink, buttonText}) {
    return (
        <CustomButton className="buy-button" isSecondary handleClick = {
            ()=> window.open(`${checkoutLink}?prefilled_email=${userEmail}`)
        }>
            {buttonText}
        </CustomButton>
    );
}

export default BuyButton;