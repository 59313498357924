import React from 'react';
import './InstructionStep.scss';

const InstructionStep = ({header, children}) => {
    return (
        <div className="instruction-step">
            <h3>
                {header}
            </h3>
            <div>
                {children}
            </div>
        </div>
    );
};

export default InstructionStep;

