import { useState } from "react";
import { Auth } from "aws-amplify";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";

function ForgotPasswordPage() {
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const [confirmation, setConfirmation] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const posthog = usePostHog();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await Auth.forgotPassword(username.trim());
      setConfirmation("Password reset email sent.");
      navigate("/reset-password", { state: { username } });
      posthog?.capture("reset_password_email_sent", {
        email: username,
      });
    } catch (error) {
      console.error("Error resetting password:", error);
      setError(error.message || "Failed to reset password.");
      posthog?.capture("reset_password_email_error", {
        email: username,
        error: error.message,
      });
    }
  };

  return (
    <div className="pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen grid">
      <SignedOutHeader />
      <form onSubmit={handleForgotPassword}>
        <div className="max-w-md mx-auto my-10 p-6 rounded-lg shadow-md bg-base-300">
          <h1 className="text-lg font-bold mb-4 text-center text-base-content">
            {t("forgot_password")}
          </h1>
          <div className="mb-4">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder={t("enter_username")}
              className="mt-1 block w-full px-3 py-2 rounded-md shadow-sm sm:text-sm input input-bordered"
            />
          </div>
          <button
            onClick={handleForgotPassword}
            className="btn btn-primary w-full bg-primary text-primary-content py-2 px-4 rounded-md hover:bg-primary"
            type="submit"
          >
            {t("reset_password")}
          </button>
          {confirmation && (
            <p className="mt-4 text-sm text-success">{confirmation}</p>
          )}
          {error && <p className="mt-4 text-sm text-error">{error}</p>}
        </div>
      </form>
      <Footer />
    </div>
  );
}

export default ForgotPasswordPage;
