import React, {Component} from 'react';
import classNames from "classnames";
import './CustomButton.scss';

class CustomButton extends Component {
    render() {
        const { handleClick, isSecondary = false, isDisabled = false, className } = this.props;
        return (
            <button
                disabled={isDisabled}
                className={
                    classNames({
                        'custom-button': true,
                        'secondary': isSecondary,
                        [className]: !!className
                    })
                }
                onClick={ handleClick }>
                {this.props.children}
            </button>
        );
    }
}

export default CustomButton;