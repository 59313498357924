import React, { Component, useState, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "../../App";
import PricingPage from "../PricingPage/PricingPage";
import PricingPage2 from "../PricingPage2/PricingPage2";
import { AuthContextProvider } from "../AuthContext.js";
import { Amplify, Auth, Hub } from "aws-amplify";
import AboutUsPage from "../../pages/AboutUsPage/AboutUsPage";
import ContactUsPage from "../../pages/ContactUsPage/ContactUsPage";
import PrivacyPolicyPage from "../../pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndConditionsPage from "../../pages/TermsAndConditionsPage/TermsAndConditionsPage";
import CancellationRefundPolicyPage from "../../pages/CancellationRefundPolicyPage/CancellationRefundPolicyPage";
import GameListPage from "../../pages/GameListPage/GameListPage";
import ShippingDeliveryPolicyPage from "../../pages/ShippingDeliveryPolicyPage/ShippingDeliveryPolicyPage";
import ConfirmationPage from "../../pages/ConfirmationPage/ConfirmationPage";
import UserPage from "../../pages/UserPage/UserPage";
import StripeSkeleton from "../../pages/StripeSkeleton/StripeSkeleton.js";
import { usePostHog } from "posthog-js/react";
import FaqPage from "../FaqPage/FaqPage.js";
import SignUpPage from "../SignUpPage/SignUpPage.js";
import SignInPage from "../SignInPage/SignInPage.js";
import ForgotPasswordPage from "../ForgotPasswordPage/ForgotPasswordPage.js";
import ConfirmAccountPage from "../ConfirmAccountPage/ConfirmAccountPage.js";
import ResetPasswordPage from "../ResetPasswordPage/ResetPasswordPage.js";
import i18n from "../../i18n.js";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <div>Sorry, that path was not found</div>,
  },
  {
    path: "stripe-skeleton",
    element: <StripeSkeleton />,
  },
  {
    path: "pricing",
    element: <PricingPage2 />,
  },
  {
    path: "faq",
    element: <FaqPage />,
  },
  {
    path: "user",
    element: <UserPage />,
  },
  {
    path: "about-us",
    element: <AboutUsPage />,
  },
  {
    path: "contact-us",
    element: <ContactUsPage />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "terms-and-conditions",
    element: <TermsAndConditionsPage />,
  },
  {
    path: "cancellation-refund-policy",
    element: <CancellationRefundPolicyPage />,
  },
  {
    path: "shipping-delivery-policy",
    element: <ShippingDeliveryPolicyPage />,
  },
  {
    path: "gamelist",
    element: <GameListPage />,
  },
  {
    path: "confirmation",
    element: <ConfirmationPage />,
  },
  {
    path: "signup",
    element: <SignUpPage />,
  },
  {
    path: "signin",
    element: <SignInPage />,
  },
  {
    path: "forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "confirm-account",
    element: <ConfirmAccountPage />,
  },
]);

const ngrokUrl =
  "https://3034-2405-201-a-907e-7df9-9d4a-ad9d-12a7.ngrok-free.app";

Amplify.configure({
  Auth: {
    region: "ap-south-1",
    userPoolId: "ap-south-1_qmxXtE0Ab",
    userPoolWebClientId: "7a8sg1malki9d6gp8cfgmsm9mn",
    mandatorySignIn: true,
    oauth: {
      domain: "auth.gameaway.in",
      scope: [
        "email",
        "openid",
        "phone",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: process.env.REACT_APP_ISDEV
        ? ngrokUrl
        : "https://www.gameaway.in",
      redirectSignOut: process.env.REACT_APP_ISDEV
        ? ngrokUrl
        : "https://www.gameaway.in",
      responseType: "code",
    },
  },
});

const AuthWrapper = () => {
  const [user, setUser] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [rpCustomerId, setRpCustomerId] = useState(null);
  const [sub, setSub] = useState(null);

  const posthog = usePostHog();

  useEffect(() => {
    const listener = ({ payload: { event, data } }) => {
      switch (event) {
        // Handle authentication events...
        case "signIn":
        case "cognitoHostedUI":
          setUser(data);
          Auth.currentSession()
            .then((data) => {
              let idToken = data.getIdToken();
              let email = idToken.payload.email;
              setUserEmail(email);
              // Here you might want to identify the user again with PostHog
              const userSub = idToken.payload.sub; // Unique identifier for the user
              setSub(userSub);
              posthog.identify(userSub, { email: email });
            })
            .catch((err) => console.log(err));
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Error", data);
          break;
        case "signOut":
          setUser(null);
          setUserEmail("");
          setStripeCustomerId(null);
          setSub(null);
          break;
        default:
          console.log("Unhandled auth event", event);
          console.log(data);
      }
    };

    Hub.listen("auth", listener);

    const storedLanguage = localStorage.getItem("language") || "en";
    i18n.changeLanguage(storedLanguage);

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        setUser(currentUser);
        const storedReferrerUsername =
          sessionStorage.getItem("referrerUsername");
        if (
          storedReferrerUsername &&
          !currentUser?.signInUserSession?.idToken.payload["custom:signedUp"]
        ) {
          Auth.updateUserAttributes(currentUser, {
            "custom:referrerUsername": storedReferrerUsername,
          }).catch((err) => console.log("Failed to update attributes:", err));
          posthog?.capture("referral_applied", {
            referrerUsername: storedReferrerUsername,
            referreeUsername: user.username,
            referreeEmail: user.email,
          });
        }
        if (
          !currentUser?.signInUserSession?.idToken.payload["custom:signedUp"]
        ) {
          Auth.updateUserAttributes(currentUser, {
            "custom:signedUp": "true",
          }).catch((err) => console.log("Failed to update signedUp:", err));
        }
        const email = currentUser?.signInUserSession?.idToken?.payload?.email;
        const userSub = currentUser?.signInUserSession?.idToken?.payload?.sub;
        const customerID =
          currentUser?.signInUserSession?.idToken?.payload?.[
            "custom:stripeCustomerId"
          ];
        const rpCustomerID =
          currentUser?.signInUserSession?.idToken?.payload?.[
            "custom:rpCustomerId"
          ];

        setUserEmail(email);
        setSub(userSub);
        setStripeCustomerId(customerID);
        setRpCustomerId(rpCustomerID);

        // Identify the user with PostHog
        posthog?.identify(userSub, {
          email: email,
          stripeCustomerId: customerID,
          rpCustomerId: rpCustomerID,
        });
      })
      .catch((error) => console.log(error));

    // Cleanup listener
    return () => {
      Hub.remove("auth", listener);
    };
  }, [posthog]);

  const signOut = async () => {
    Auth.signOut();
  };

  const signIn = async () => {
    Auth.federatedSignIn();
  };

  return (
    <AuthContextProvider
      value={{
        user,
        userEmail,
        signOut,
        signIn,
        stripeCustomerId,
        rpCustomerId,
        sub,
      }}
    >
      <RouterProvider router={router} />
    </AuthContextProvider>
  );
};

export default AuthWrapper;
