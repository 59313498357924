import React, { Component } from "react";
import PayOrStartTrial from "../../components/PayOrStartTrial/PayOrStartTrial";
import Dashboard from "../../components/Dashboard/Dashboard";
// import logoutSymbol from "../../../public/logout-icon.svg"
import "./AfterSigninPage.scss";
import GameawayHeader from "../../components/GameawayHeader/GameawayHeader";
import Footer from "../../components/Footer/Footer";
import SignedOutHeader from "../../components/SignedOutHeader/SignedOutHeader";
import AuthContext from "../../components/AuthContext";

class AfterSigninPage extends Component {
  static contextType = AuthContext;
  render() {
    const {
      userEmail,
      signOut,
      hideTrialDecisionPage,
      setTrialDecisionMade,
      user,
    } = this.props;
    return (
      <div className="aftersigning-page pt-0 max-w-[1245px] px-[24px] my-0 mx-auto min-h-screen">
        <SignedOutHeader context={this.context} />
        <section className="aftersignin-main">
          {/*  Check if the current user has seen the Pay/Start trial and show corresponding component  */}
          {!hideTrialDecisionPage ? (
            <PayOrStartTrial
              setTrialDecisionMade={setTrialDecisionMade}
              userEmail={userEmail}
            />
          ) : (
            <Dashboard user={user} userEmail={userEmail} signOut={signOut} />
          )}
        </section>
        <Footer />
      </div>
    );
  }
}

export default AfterSigninPage;
