import React from "react";
import { gameList } from "../../gameList";
import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import AuthContext from "../AuthContext.js";
import Footer from "../Footer/Footer.js";

class GameList extends React.Component {
  static contextType = AuthContext;
  render() {
    return (
      <div>
        <SignedOutHeader context={this.context} />
        <div className="text-4xl font-extrabold no-underline pb-6">
          Game List
        </div>
        <div className="pb-6 text-2xl font-bold dark:text-white">
          Note: You need to own the games to play them on our service
        </div>
        <div className="pb-6 text-xl dark:text-white">
          * Indicates Free to Play
        </div>
        <div className="flex flex-col w-full h-fit gap-6">
          <div className="grid card bg-base-300 rounded-box place-items-center pt-4 pb-6">
            <h5 className="text-xl font-bold">Steam</h5>
            <ul className="grid grid-cols-1 md:grid-cols-3 w-fit gap-6 pt-6">
              {gameList
                .filter((game) => game.store === "Steam")
                .map((game) => {
                  return (
                    <li key={game.name}>
                      <div className="card w-full md:w-80 bg-accent shadow-xl">
                        <div className="card-body flex flex-row items-center">
                          <div className="chat-image avatar">
                            <div className="w-14 rounded-full">
                              <img
                                alt="Game Image"
                                src="./android-chrome-192x192.png"
                              />
                            </div>
                          </div>
                          <h2 className="text-accent-content pl-6 card-title no-underline">
                            {game.name} {game.free ? "*" : ""}
                          </h2>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="divider"></div>
          <div className="grid card bg-base-300 rounded-box place-items-center pt-4 pb-6">
            <h5 className="text-xl font-bold">Epic</h5>
            <ul className="grid grid-cols-1 md:grid-cols-3 w-fit gap-6 pt-6 center">
              {gameList
                .filter((game) => game.store.trim() === "Epic")
                .map((game) => {
                  return (
                    <li key={game.name}>
                      <div className="card w-full md:w-80 bg-accent shadow-xl">
                        <div className="card-body flex flex-row items-center">
                          <div className="chat-image avatar">
                            <div className="w-14 rounded-full">
                              <img
                                alt="Game Image"
                                src="./android-chrome-192x192.png"
                              />
                            </div>
                          </div>
                          <h2 className="text-accent-content pl-6 card-title no-underline">
                            {game.name} {game.free ? "*" : ""}
                          </h2>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          <div className="divider"></div>
          <div className="grid card bg-base-300 rounded-box place-items-center pt-4 pb-6">
            <h5 className="text-xl font-bold">Other</h5>
            <ul className="grid grid-cols-1 md:grid-cols-3 w-fit gap-6 pt-6 center">
              {gameList
                .filter(
                  (game) =>
                    game.store.trim() !== "Epic" && game.store !== "Steam"
                )
                .map((game) => {
                  return (
                    <li key={game.name}>
                      <div className="card w-full md:w-80 bg-accent shadow-xl">
                        <div className="card-body flex flex-row items-center">
                          <div className="chat-image avatar">
                            <div className="w-14 rounded-full">
                              <img
                                alt="Game Image"
                                src="./android-chrome-192x192.png"
                              />
                            </div>
                          </div>
                          <h2 className="text-accent-content pl-6 card-title no-underline">
                            {game.name} {game.free ? "*" : ""}
                          </h2>
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default GameList;
