import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmationPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Log the referrer for debugging
        console.log('Referrer:', document.referrer);

        // Extract query parameters
        const queryParams = new URLSearchParams(window.location.search);
        const productId = queryParams.get('product_id');
        const price = queryParams.get('price');

        // Log the query parameters for debugging
        console.log('Query Parameters:', {
            productId: productId,
            price: price
        });

        // Validate the parameters (make sure they exist)
        if (!productId || !price) {
            console.error("Required parameters are missing.");
            navigate('/');
            return;
        }

        // Push data to GTM dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'purchase',
            'productId': productId,
            'price': price,
        });

        // Log the data layer push for debugging
        console.log('Data Layer:', window.dataLayer);

        // Redirect to the main page
        setTimeout(() => {
            console.log('Redirecting to home page...');
            navigate('/');
        }, 2000); // Delay the redirect slightly to ensure data is sent to GTM
    }, [navigate]);

    return (
        <div>
            <h1>Processing your purchase...</h1>
            <p>You will be redirected shortly.</p>
        </div>
    );
};

export default ConfirmationPage;