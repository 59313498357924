import React, { Component } from "react";
import {
  MdExpandLess,
  MdExpandMore,
  MdOutlineArrowRightAlt,
} from "react-icons/md";
import InstructionStep from "../InstructionStep/InstructionStep";
import MoonLightLinks from "../MoonlightLinks/MoonlightLinks";

import "./InstructionsContainer.scss";
import { withTranslation } from "react-i18next";

class InstructionsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      areInstructionsExpanded: false,
      steamCredentialsExpanded: false,
    };
  }
  render() {
    const { t } = this.props;
    return (
      <section className="instructions-container">
        <div
          className="instructions-header"
          onClick={() => {
            this.setState((prevState) => ({
              areInstructionsExpanded: !prevState.areInstructionsExpanded,
            }));
          }}
        >
          <div className="text-2xl pb-2">{t("how_to_start_playing")}</div>
          <span>
            {this.state.areInstructionsExpanded ? (
              <MdExpandLess size="1.5rem" />
            ) : (
              <MdExpandMore size="1.5rem" />
            )}
          </span>
        </div>
        {this.state.areInstructionsExpanded && (
          <div>
            {
              <div>
                <iframe
                  className="w-fit"
                  height="100%"
                  src="https://www.youtube-nocookie.com/embed/x1mRGYrcUNE"
                  title="Get started with cloud gaming on Gameaway"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
                <p>
                  <strong>OR</strong>
                </p>
              </div>
            }
            <InstructionStep header="Step 1">
              <MoonLightLinks />
            </InstructionStep>
            <InstructionStep header="Step 2">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("instruction_select_game"),
                }}
              />
            </InstructionStep>
            <InstructionStep header="Step 3">
              {t("instruction_click_start_game")}
            </InstructionStep>
            <InstructionStep header="Step 4">
              {t("instruction_open_moonlight")}
            </InstructionStep>
            <InstructionStep header="Step 5">
              {t("instruction_copy_ip_address")}
            </InstructionStep>
            <InstructionStep header="Step 6">
              {t("instruction_enter_pin")}
            </InstructionStep>
            <InstructionStep header="Step 7">
              {t("instruction_set_video_codec")}
              <div className="moonlight-screenshots">
                <img
                  alt="moonlight-codec-screenshot"
                  src={`./moonlight-hevc.png`}
                />
              </div>
            </InstructionStep>
            <InstructionStep header="Step 8">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("instruction_run_desktop"),
                }}
              />
              <div className="moonlight-screenshots">
                <img src="./moonlight-before.png" />
                <MdOutlineArrowRightAlt size="3rem" />
                <img src="./moonlight-after.png" />
              </div>
            </InstructionStep>
            <InstructionStep header="Step 9">
              {t("instruction_log_in_steam")}
              <br />
              <strong>{t("instruction_important")}</strong>
            </InstructionStep>
            <InstructionStep header="Step 10">
              {t("instruction_stop_instance")}
            </InstructionStep>
            <InstructionStep header="Step 11">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("instruction_check_cloud_saves"),
                }}
              />
              <div className="moonlight-screenshots">
                <img src="./steam-cloud.png" />
              </div>
            </InstructionStep>
            <h4>{t("instruction_use_controllers")}</h4>
          </div>
        )}
      </section>
    );
  }
}

export default withTranslation()(InstructionsContainer);
